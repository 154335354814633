import React from "react";

const AboutsUs = () => {
  return (
    <section id="about-us" className="block spacer p-top-xl">
      <div className="wrapper">
        <div className="row gutter-width-sm">
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
            <div className="title">
              <h2>
                Offrez-vous un moment de bien-être et de détente grâce à mes 20
                ans d’expérience dans le domaine de l’esthétique qui est ma
                passion. Vous offrir un instant rien qu’à vous pour une
                relaxation garantie !
              </h2>
            </div>
          </div>
        </div>

        <div className="row gutter-width-sm justify-content-end"></div>
      </div>
    </section>
  );
};

export default AboutsUs;
