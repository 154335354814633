import React from "react";
import { useSelector } from "react-redux";
import SearchModal from "../../components/modal/SearchModal";
const ModalMenuPrimary = () => {
  const user = useSelector((state) => state.user.value);
  return (
    <nav className="menu-primary">
      <ul className="nav">
        <li
          className={
            "nav-item" +
            (window.location.pathname === "/" ? " current-nav-item" : "")
          }
        >
          <a title="Home" href={process.env.PUBLIC_URL + "/"}>
            Accueil
          </a>
        </li>

        <li
          className={
            "nav-item" +
            (window.location.pathname === "/services"
              ? " current-nav-item"
              : "")
          }
        >
          {/* <a title="Services" href={process.env.PUBLIC_URL + "/services"}>
            Services
          </a> */}
        </li>

        <li
          className={
            "nav-item" +
            (window.location.pathname === "/gallery" ? " current-nav-item" : "")
          }
        >
          <a title="Gallery" href={process.env.PUBLIC_URL + "/gallery"}>
            Galerie
          </a>
        </li>

        <li
          className={
            "nav-item" +
            (window.location.pathname === "/reservation-page"
              ? " current-nav-item"
              : "")
          }
        ></li>

        {/* <li
          className={
            "nav-item" +
            (window.location.pathname === "/profile" ? " current-nav-item" : "")
          }
        >
          {user.token ? (
            <a title="Contacts" href={process.env.PUBLIC_URL + "/profile"}>
              Mon profil
            </a>
          ) : (
            <SearchModal text="Se connecter" reservation={true} />
          )}
        </li> */}
      </ul>
    </nav>
  );
};

export default ModalMenuPrimary;
