import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Helmet } from "react-helmet";
import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import PageTitleServicesInside from "../blocks/page-title/PageTitleServicesInside";
import Footer from "../blocks/footer/Footer";
import BackToServices from "../components/button/BackToServices";
import { useParams } from "react-router-dom";
import ServicesContentItemsData from "../data/services/servicesContentItems.json";
import SearchModal from "../components/modal/SearchModal";
import { useSelector } from "react-redux";
import ReservationModal from "../components/modal/ReservationModal";
import { Toaster } from "react-hot-toast";
const ServicesInside = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      document.body.classList.remove("single");
      document.body.classList.remove("single-adveits_services");
    };
  }, []);

  const user = useSelector((state) => state.user.value);
  document.body.classList.add("single");
  document.body.classList.add("single-adveits_services");
  const { id } = useParams();

  const allProducts = ServicesContentItemsData[id - 1].soins.products.map(
    (product, i) => {
      return (
        <div key={i} className="price-list-item">
          <div className="price-list-item-title">
            <h3>{product.title}</h3>
          </div>

          <ul className="price-list-item-list-group list-group">
            {product.prestations.map((presta, j) => {
              return (
                <li
                  key={j}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <span className="list-group-title">
                    Pour {presta.time} minutes
                  </span>
                  <span className="list-group-title">{presta.price} €</span>
                  {/* {user.token ? (
                    <span>
                      <ReservationModal
                        product={presta}
                        title={product.title}
                        service={ServicesContentItemsData[id - 1].title}
                      />
                    </span>
                  ) : (
                    <span>
                      <SearchModal
                        text={`${presta.price} €`}
                        reservation={true}
                        page={`services-inside/${id}`}
                      />
                    </span>
                  )} */}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
  );

  return (
    <Fragment>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>
          Service inside | Julie Bengueddach - Esthéticienne professionelle
        </title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta
          name="google-site-verification"
          content="-sL3qrR8XuJRi2vK6gvyph3PkILJ22ISSOo35kEVpXo"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=no"
        />
        <title>{ServicesContentItemsData[id - 1].title}</title>
        <meta
          name="description"
          content={ServicesContentItemsData[id - 1].soins.description}
        />
        <meta
          name="keywords"
          content={ServicesContentItemsData[id - 1].soins.title}
        />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </Helmet>

      <Loading />
      <Toaster />

      <Header logoColor="light" />

      <main id="main" className="site-main">
        <PageTitleServicesInside
          title={ServicesContentItemsData[id - 1].title}
        />
        <div id="page-content" className="spacer p-top-xl">
          <div className="wrapper">
            <div className="content">
              <div className="clearfix">
                <div id="single">
                  <div id="single-content">
                    <div id="img" className="block">
                      <div className="img-no-wrap-1">
                        <div className="img object-fit">
                          <div className="object-fit-cover">
                            <img
                              loading="lazy"
                              src={
                                ServicesContentItemsData[id - 1].soins.imageUrl
                              }
                              alt={ServicesContentItemsData[id - 1].title}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="description" className="block spacer p-top-xl">
                      <div className="description">
                        <p>
                          {ServicesContentItemsData[id - 1].soins.introduction}
                        </p>
                        <p>
                          {ServicesContentItemsData[id - 1].soins.description}
                        </p>
                        <p>
                          Nous vous proposons des créneaux de rendez-vous du
                          lundi au vendredi, de 9h30 à 19h00 et le mercredi à
                          domicile de 11h00 à 18h00.
                        </p>
                        <p>
                          Découvrez ci-dessous nos différentes prestations
                          disponibles pour ce service, et leurs tarifs :
                        </p>
                      </div>
                    </div>

                    <div id="price-list" className="block spacer p-top-xl">
                      <div className="title">
                        <h2 className="hr">Tarif des prestations</h2>
                      </div>

                      <div className="price-list">
                        <div className="price-list-items">{allProducts}</div>
                      </div>
                    </div>
                    <BackToServices />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </Fragment>
  );
};

export default ServicesInside;
